<template>
  <div class="md:px-24 px-4">
    <div>
      <div class="fixed top-0 w-screen h-screen bg-stone-800 bg-opacity-40 left-0 z-[-1]"></div>
      <img src="/gelado.png" alt="" class="fixed w-[45vw] md:w-[35vw] left-0 bottom-0 z-[-2]">
      <img src="/filipinos.png" alt="" class="fixed w-[45vw] md:w-[35vw] right-0 bottom-0 z-[-2]">
    </div>
    <img src="/logo.png" alt="" class="mx-auto w-72 mb-8 md:mb-4">
    <div class="md:flex justify-center mt-0 gap-24">
      <div class="text-center">
        <img :src="`/photos/${user}_post.png`" alt="" class="rounded-md h-[60vh] mx-auto border-yellow-600">
        <div class="mt-4 sm:flex sm:items-center sm:justify-center sm:gap-4">
          <strong class="font-medium">Feed format</strong>

          <span class="hidden sm:block sm:h-px sm:w-8 sm:bg-yellow-500"></span>

          <p class="mt-0.5 opacity-50 sm:mt-0">1080x1350</p>
        </div>
      </div>
      <div>
      <img :src="`/photos/${user}_story.png`" alt="" class="rounded-md h-[60vh] mx-auto mt-8 md:mt-0">
        <div class="mt-4 sm:flex sm:items-center sm:justify-center sm:gap-4">
          <strong class="font-medium">Story format</strong>

          <span class="hidden sm:block sm:h-px sm:w-8 sm:bg-yellow-500"></span>

          <p class="mt-0.5 opacity-50 sm:mt-0">1080×1920</p>
        </div>
      </div>
    </div>
    <button
        class="group md:mt-4 mt-12 mb-24 relative inline-flex items-center overflow-hidden rounded bg-yellow-600 px-8 py-3 text-white focus:outline-none focus:ring active:bg-indigo-500"
        @click="downloadAllImages"
    >
    <span class="absolute -start-full transition-all group-hover:start-4">
      <svg
          class="size-5 rtl:rotate-180"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
      >
        <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M17 8l4 4m0 0l-4 4m4-4H3"
        />
      </svg>
    </span>

      <span class="text-sm font-medium transition-all group-hover:ms-4"> Download </span>
    </button>

  </div>
</template>

<script>
import {computed} from "vue";

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  setup(){
    const user = computed(() => {
      return window.location.search.split('?name=').pop()
    });

    const downloadImage = (url) => {
      const link = document.createElement('a');
      link.href = url;
      link.download = url.split('/').pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const downloadAllImages = () => {
      const urls = [
        `/photos/${user.value}_post.png`,
        `/photos/${user.value}_story.png`
      ];
      urls.forEach((url, index) => {
        setTimeout(() => downloadImage(url), index * 2000);
      });
    };

    return { user, downloadAllImages };
  }

}
</script>
