import { createMemoryHistory, createRouter } from 'vue-router'

import HelloWorld from "@/components/HelloWorld.vue";

const routes = [
    { path: '/:user?', component: HelloWorld },
]

const router = createRouter({
    mode: 'history',
    history: createMemoryHistory(),
    routes,
})

export default router